@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

* {
  font-family: "Raleway", sans-serif;
}

.ourProduct {
  background-image: url("./assets/images//product-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.shadowBg {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

 
.slider {
  width: 100%;
  overflow-x: hidden;
}

.slider-track {
  display: flex;
}

.slide {
  width: 100%;
}
 
.slider-track.left-to-right {
  animation: scroll 15s linear infinite;
}

 
.slider-track.right-to-left {
  animation: scrollRight 15s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scrollRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}


.slider::-webkit-scrollbar {
  display: none;
}
